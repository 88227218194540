<template>
  <app-wrapper>
    <template v-slot:child> Analysis </template>
  </app-wrapper>
</template>

<script>
import AppWrapper from '@/layout/AppWrapper'

export default {
    name: 'AllProductAnalysis',
    components: { AppWrapper },
}
</script>

<style scoped></style>
